import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/bluescope/layout"
import seologo from "../../images/bluescope/appicon256.png"
import Seo from "../../components/SEO"

function BlueScopePrivacyPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "BlueScope for iOS app logo",
    };

    const appStoreCampaignUrlBlueScope = "https://apps.apple.com/app/apple-store/id1530096924?pt=121290356&ct=malaudhome&mt=8"

    return (
    <Layout>
        <Seo
            siteTitle={'BlueScope® Privacy Policy'}
            seoTitle='BlueScope Privacy Policy'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='BlueScope® for iOS - Privacy Policy'
            keywords={["BlueScope", "BlueScope Privacy Policy"]}
            datePublished="12-13-2020"
            dateModified="05-08-2023"
        />
        
        <div className={"container"} style={{textAlign: 'left'}}>
            <div className={"content"}>
                <div className={"title"}>
                    <a href={appStoreCampaignUrlBlueScope} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}} title={"BlueScope for iOS"}>
                        <h1 style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope® for iOS</h1>
                    </a>

                    <h2>Privacy Policy</h2>
                </div>

                <div>
                <h6 style={{textAlign: 'left'}}>
                    Last Updated: May 08, 2023
                </h6>
                </div>

                <p>
                    We value your privacy and believe that everyone has the right to protect their personal data.
               </p>
               <p>
                    This page describes the privacy policy for <a href={appStoreCampaignUrlBlueScope} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}} title={"BlueScope for iOS"}>BlueScope® for iOS</a> app. To read about the privacy policy for this website, please visit <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>this link</Link>.
                </p>
                


                <h3>Data we don't collect</h3>

                <p style={{padding: 0}}>
                    <span style={{fontWeight: 'bold'}}>Personally Identifiable Data: </span> Any personally identifiable data is not collected, such as user name, email, phone number, address, contacts etc.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Bluetooth® Device Data: </span> BlueScope® attempts to gather basic data about nearby Bluetooth® devices. This data is used to display information about discovered Bluetooth® devices within the app. When devices are moved to 'My Devices' list, this data is stored on the user's device for later display within the app. This data is not collected or shared with any third parties.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Bluetooth® Device Location Data: </span> When location services are enabled, the location of the nearby Bluetooth® devices is also stored on the device, if available. This information is used to display the last location of the discovered Bluetooth® devices. This location data is not collected or shared with any third parties.
                </p>


                <br />

                <h3>Data we collect and how it's used</h3>

                <p>
                    <span style={{fontWeight: 'bold'}}>Location Data: </span>To comply with GDPR in Europe, and CCPA in California, device's IP address may be used to determine the general location of the device. This information is used to comply with the consumer privacy laws, such as GDPR in EEA/UK, and CCPA in California. This data may also be shared with Google, and other third parties to display non-personalized advertisements within the app. The location data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Performance Data: </span>Certain performance data may be collected, such as app launch time, hang rate, energy usage, etc, to evaluate user behavior, and understand the effectiveness of existing product features. The performance data may also be used to display non-personalized advertisements within the app. The performance data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Diagnostic Data: </span>Diagnostic data, such as crash logs, may be used to diagnose problems and improve the app. Diagnostic data may also be used to display non-personalized advertisements within the app. The diagnostic data is not used for tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Identifiers: </span>App based device identifiers are used for app functionality and to verify any in-app purchase transactions. Device identifiers may also be used for analytics, and to display non-personalized advertisements within the app. These identifiers are not used tracking purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Advertisement Data: </span>Advertisement data, such as advertisements displayed, or seen by the user, within the app, may be used for analytics purposes, and to improve advertising performance.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Other Diagnostic Data: </span>When you have enabled sharing anonymous diagnostic information on your devices, Apple may share any app related diagnostic information, crash logs, etc. with us. This information does not contain any personal data, and is only used to diagnose problems and improve app performance.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Support Data: </span>When you contact support, you provide your email, name and other information that may be relevant to your support query. This information will be stored on our servers and for the sole purpose of answering your support request and for legal & administrative purposes.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>GDPR: </span>BlueScope® fully complies with GDPR and EU consent policy. Users in Europe Economic Area (EEA) and UK, are required to provide consent to opt-in to any data collection and usage. BlueScope® uses Google's IAB compliant, User Messaging Platform, to gather user consent where required. When consent is provided, non-personalized advertisements may be displayed according to the given consent. Users may make changes or withdraw the consent at any time, by using the consent form link, in the app preferences.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>CCPA: </span>We do NOT sell your personal information, and personalized advertisements are not displayed. To comply with CCPA in California, restricted data processing has been enabled. With restricted data processing, Google will restrict how it uses certain unique identifiers and other data processed in the provision of services. For example, Google will not create or update profiles for ads personalization or use existing profiles to serve personalized ads relating to data to which restricted data processing applies.
                </p>

                <p>
                    To know more about how Google uses this data, visit <a href="https://policies.google.com/technologies/partner-sites" target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}}>https://policies.google.com/technologies/partner-sites</a>
                </p>

                <br />
                <hr style={{width: '30%'}}></hr>
                <br />
                {/* <p>---</p> */}

                {/* <h3>Consent</h3> */}
                <p>
                    By using our website, you hereby consent to our website <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>Privacy Policy</Link>.
                    If you have additional questions or require more information, please <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link>.
                </p>

            </div>
        </div>

        <div className={"container"}>
            <div className={"notes"}>
                <ul>
                    <li>BlueScope® is a registered trademark of Malaud LLC.</li>
                    <li>Bluetooth® is a registered trademark of Bluetooth SIG.</li>
                    <li>
                        Apple®, iOS®, iPhone®, iPad®, App Store®, Apple Watch®, Apple Maps®, AirPods®, Siri® are registered trademarks of Apple Inc.
                    </li>
                </ul>      
            </div>
        </div>

    </Layout>
    )
}

export default BlueScopePrivacyPage
